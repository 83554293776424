
<template>
    <div id="knowledge-base-page">
        <!-- JUMBOTRON -->
        <div class="knowledge-base-jumbotron">
            <div class="knowledge-base-jumbotron-content lg:p-16 md:p-16 sm:p-16 p-8 rounded-lg mb-base">
                <h1 class="mb-1 text-white">Welcome </h1>
                
            </div>
        </div>

        <!-- KNOWLEDGE BASE CARDS  -->
        <div class="vx-row">
                <div class="vx-col w-full md:w-1/3 sm:w-1/2 mb-base" v-for="item in filteredKB" :key="item.id" @click="$router.push(item.url).catch(() => {})">
                    <vx-card class="text-center cursor-pointer">
                        <img :src="item.graphic" alt="graphic" width="180" class="mx-auto mb-4">
                        <h4 class="mb-2">{{ item.title.toUpperCase() }}</h4>
                        <small>{{ item.description }}</small>
                    </vx-card>
                </div>
            </div>
    </div>
</template>

<script>

export default{
    data() {
        return {
            knowledgeBaseSearchQuery: '',
            kb: [
               
                
            ]
        }
    },
    computed: {
        filteredKB() {
            return this.kb.filter((item) => item.title.toLowerCase().includes(this.knowledgeBaseSearchQuery.toLowerCase()) || item.description.toLowerCase().includes(this.knowledgeBaseSearchQuery.toLowerCase()));
        }
    },
    created()
    { 
         this.$store.commit('auth/SET_BEARER', localStorage.getItem("accessToken"))
    },
   
    methods: {},
    components: {},
   
}
</script>

<style lang="scss">
.knowledge-base-jumbotron-content {
    background-image: url('../assets/images/pages/knowledge-base-cover.jpg');
    background-size: cover;
}
</style>